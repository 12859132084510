<template>
  <div class="lefty">
    <br />
    <router-link to="/">
      <input class="btn btn-primary btn-lg" type="submit" value="Home" />
    </router-link>

    <h3 class="text-left">
      Privacy Policy <br /><br />
      <small class="text-muted"> Privacy Policy for wordlister.org</small>
    </h3>
    <br />
    <p class="text-left">
      The privacy of visitors to wordlister.org is important to us.
    </p>

    <p class="text-left">
      At wordlister.org, we’re committed to protecting and respecting your
      privacy. This Privacy Policy (“Privacy Policy”) governs your access to and
      use of this Website, wordlister.org (the “Website”), and associated
      content.
    </p>
    <p class="text-left">
      This Privacy Policy explains when and why we collect personal information
      about individuals who visit our Website and how we use the personal
      information, and the conditions under which we may disclose your personal
      information to others.
    </p>
    <p class="text-left">
      We may change this Privacy Policy from time to time so please check this
      page occasionally to ensure that you are happy with any changes. By using
      our Website or our Services, you are agreeing to be bound by this Privacy
      Policy.
    </p>

    <h4 class="text-left">General Data Protection Regulation (GDPR)</h4>
    <p class="text-left">We are a Data Controller of your information.</p>

    <p class="text-left">
      wordlister.org’s legal basis for collecting and using the personal
      information described in this Privacy Policy depends on the Personal
      Information we collect and the specific context in which we collect that
      information:
    </p>

    <ul class="list-group">
      <li class="text-left">
        By using the site, you provide wordlister.org permission to collect
        information
      </li>
      <li class="text-left">
        Processing your personal information is in wordlister.org’s legitimate
        interests
      </li>
      <li class="text-left">wordlister.org will comply with applicable laws</li>
    </ul>
    <br />
    <p class="text-left">
      wordlister.org will retain your personal information only for as long as
      is necessary for the purposes set out in this Privacy Policy. We will
      retain and use your information to the extent necessary to enforce our
      policies.
    </p>

    <p class="text-left">
      If you are a resident of the European Economic Area (EEA), you have
      certain data protection rights. The only information Wordlister.org
      currently collects from users is that necessary for preventing possible
      platform interference, whether intentional or unintentional (DDOS or DOS
      attacks, or various hacking attempts).
    </p>

    <h4 class="text-left">Log Files</h4>
    <p class="text-left">
      wordlister.org follows a standard procedure of using log files. These
      files log visitors when they visit websites. As a reference point, most
      hosting companies do this as a normal course of business.
    </p>

    <p class="text-left">
      The information collected in these log files includes:
    </p>

    <ul class="list-group">
      <li class="text-left">
        IP (Internet Protocol) Address
      </li>

      <li class="text-left">
        ISP (Internet Service Provider)
      </li>

      <li class="text-left">
        Web Browser type
      </li>

      <li class="text-left">
        Date and time stamp
      </li>
    </ul>
    <br />
    <p class="text-left">
      The purpose of the information is for administering the site.
    </p>

    <h4 class="text-left">Children's Information</h4>
    <p class="text-left">
      Another important part of online privacy is adding protection for children
      while using the internet. We encourage parents and guardians to observe,
      participate in, and/or monitor and guide their online activity.
    </p>

    <p class="text-left">
      wordlister.org does not knowingly collect any Personal Identifiable
      Information from children.
    </p>

    <h4 class="text-left">Consent</h4>
    <p class="text-left">
      By using our website, you hereby consent to our Privacy Policy and agree
      to its terms.
    </p>

    <p class="text-left">Updated on 19 Oct 2020</p>

    <h3 class="text-left">Disclaimer</h3>
    <p class="text-left">
      SCRABBLE® is a registered trademark. All intellectual property rights in
      and to the game are owned in the U.S.A and Canada by Hasbro Inc., and
      throughout the rest of the world by J.W. Spear & Sons Limited of
      Maidenhead, Berkshire, England, a subsidiary of Mattel Inc. Mattel and
      Spear are not affiliated with Hasbro. Words with Friends is a trademark of
      Zynga with Friends.
    </p>

    <p class="text-left">
      wordlister.org is not affiliated with SCRABBLE®, Mattel Inc, Hasbro Inc,
      Words with Friends, or Zynga Inc in any way. This site is for
      entertainment purposes only.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
lefty {
  text-align: left;
}
</style>
